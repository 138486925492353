<template>
	<div>
		<van-steps :active="step_active" active-icon="success" active-color="#ff976a">
			<van-step>{{translate('bank_option')}}</van-step>
			<van-step>{{translate('bank_withdrawal_amount')}}</van-step>
			<van-step>{{translate('bank_accounts_receivable')}}</van-step>
			<van-step>{{translate('bank_account_information')}}</van-step>
			<van-step>{{translate('bank_moderated')}}</van-step>
		</van-steps>

		<h4 class="h4_title">{{translate('bank_withdrawal_amount')}}</h4>

		<van-cell-group>
			<van-cell :title="translate('banks_withdrawal_amount')" :value="formItem.symbol + formItem.total" />
		</van-cell-group>
		
		
		<h4 class="h4_title">{{translate('account_type')}}</h4>
		
		<van-cell-group>
			<van-cell :title="translate('account_type')" :value="bank_type_text" />
		</van-cell-group>

		<h4 class="h4_title">{{translate('banks_account_information')}}</h4>

		<van-form @submit="next">
			<template v-if="type == 2">
				<van-field 
					v-model="hk.country" 
					:label="translate('bank_nation')" 
					:placeholder="translate('bank_please_enter')"
					:rules="hkRules.country"
					required
				/>
				
				<van-field 
					v-model="hk.bank" 
					:label="translate('amount_withdrawal_amount')" 
					:placeholder="translate('bank_please_enter')"
					:rules="hkRules.bank"
					required
				/>

				<van-field 
					v-model="hk.bank_code" 
					:label="translate('bank_code')" 
					:placeholder="translate('bank_please_enter')"
					:rules="hkRules.bank_code"
					required
				/>

				<van-field 
					v-model="hk.bank_address" 
					:label="translate('bank_address')" 
					:placeholder="translate('bank_please_enter')"
					:rules="hkRules.bank_address"
					required
				/>
				<van-field 
					v-model="hk.account_name" 
					:label="translate('bank_account_name')" 
					:placeholder="translate('bank_please_enter')"
					:rules="hkRules.account_name"
					required
				/>
				
				<van-field 
					v-model="hk.account_no" 
					:label="translate('bank_bank_card_number')" 
					:placeholder="translate('bank_please_enter')"
					:rules="hkRules.account_no"
					required
				/>
				<van-field
					v-model="hk.account_address" 
					:label="translate('bank_account_address')" 
					:placeholder="translate('bank_please_enter')"
					:rules="hkRules.account_address"
					required
				/>

				<van-field
					v-model="hk.swift_code"
					label="SWIFT CODE"
					:placeholder="translate('bank_please_enter')"
					:rules="hkRules.swift_code"
					required
				/>
			</template>
			

			<template v-if="type == 3">
				<van-field
					readonly
					clickable
					:label="translate('bank_main_network_type')"
					:value="indexType > -1 ? types[indexType].text : ''"
					:placeholder="translate('please_select_the_main_network')"
					@click="showType = true"
					:rules="usdtRules.type"
					required
				/>
				<van-popup v-model="showType" round position="bottom">
					<van-picker
						:title="translate('bank_main_network_type')"
						show-toolbar
						:columns="types"
						:default-index="indexType"
						@cancel="showType = false"
						@confirm="onConfirmType"
					/>
				</van-popup>
				
				<van-field 
					v-model="usdt.address" 
					:label="translate('banks_address')" 
					:placeholder="translate('bank_please_enter')"
					required
					:rules="usdtRules.address"
					/>

				<van-field v-model="usdt.usdt_image" required :rules="usdtRules.usdt_image" :label="translate('qrcode_picture')">
					<template #input>
						<van-image 
							width="100" 
							height="100" 
							:src="image_preview" 
							style="vertical-align: middle;" 
							@click="qrcodeUpload()"/>
					</template>
				</van-field>
			</template>

			<div class="common_block">
				<van-button type="warning" block native-type="submit">{{translate('bank_confirm_submission')}}</van-button>
			</div>

		</van-form>

	</div>
</template>

<script>
	export default {
		name: 'chujin_bank',
		data() {
			return {
				step_active: 3,

				hk: {
					name: "",
					country: "",
					bank: "",
					bank_code: "",
					bank_address: "",
					account_name: "",
					account_no: "",
					account_address: "",
					swift_code: "",
				},

				hkRules: {
					country: [
						{
							required: true,
							message: this.translate('bank_please_fill_in_the_country'),
							trigger: 'onBlur'
						}
					],
					bank: [
						{
							required: true,
							message: this.translate('bank_please_fill_in_the_bank'),
							trigger: 'onBlur'
						}
					],
					bank_code: [
						{
							required: true,
							message: this.translate('bank_please_fill_in_the_bank_code'),
							trigger: 'onBlur'
						}
					],
					bank_address: [
						{
							required: true,
							message: this.translate('bank_please_fill_in_the_bank_address'),
							trigger: 'onBlur'
						}
					],
					account_name: [
						{
							required: true,
							message: this.translate('bank_please_fill_in_the_account_name'),
							trigger: 'onBlur'
						}
					],
					account_no: [
						{
							required: true,
							message: this.translate('bank_please_fill_in_the_bank_number'),
							trigger: 'onBlur'
						}
					],
					account_address: [
						{
							required: true,
							message: this.translate('bank_please_fill_account_address'),
							trigger: 'onBlur'
						}
					],
					swift_code: [
						{
							required: true,
							message: this.translate('bank_please_fill_in_the_swift_code'),
							trigger: 'onBlur'
						}
					],
				},

				usdt: {
					address: "",
					type: "1",
					type_text: "",
					usdt_image: ""
				},

				usdtRules: {
					type: [
						{
							required: true,
							message: this.translate('bank_please_select_the_main_network'),
							trigger: 'onBlur'
						}
					],
					address: [
						{
							required: true,
							message: this.translate('bank_please_fill_in_the_address'),
							trigger: 'onBlur'
						}
					],
					usdt_image: [
						{
							required: true,
							message: this.translate('please_upload_qrcode_picture'),
							trigger: 'onBlur'
						}
					]
				},

				bank_type_text: "",
				type: "0",

				indexType: -1,
				showType: false,
				types: [ {type: 1, text: this.translate('recommend')}, {type: 3, text: this.translate('trc20')}, {type: 2, text: this.translate('omni')}],	

				image_preview: this.$axios.defaults.resourceURL + "images/img_upload.png",

				formItem : {
					c: "",
					market: 'HK',
					symbol: "",
					total: "",
					token: "",
					bank_type: "",
					bank_info: "",
				}
			}
		},
		mounted() {
			if (this.$route.query.token == "" || this.$route.query.token == undefined) {
				this.$router.replace('/nologin')
			} else {
				if (window.sessionStorage.getItem('total') == undefined
						|| window.sessionStorage.getItem('symbol') == undefined) {

					this.$router.replace('/chujin/index?token=' + this.$route.query.token)
				}

				this.formItem.token = this.$route.query.token
				this.formItem.symbol = window.sessionStorage.getItem('symbol')
				this.formItem.total = window.sessionStorage.getItem('total')
				this.formItem.bank_type = window.sessionStorage.getItem('bank_type')
				this.formItem.c = window.sessionStorage.getItem('c')

				this.type = window.sessionStorage.getItem('bank_type')

				switch(this.type) {
					case '1':
						this.bank_type_text = this.translate('bank_domestic_bank_account')

						break

					case '2':
						this.bank_type_text = this.translate('bank_overseas_bank_account')

						break

					case '3':
						this.bank_type_text = 'USDT'

						break

				}

				window.set_qrcode_pic = this.set_qrcode_pic

				this.init()
			}
		},
		methods: {
			qrcodeUpload() {
				if (window.JsObject != null) {
					window.JsObject.usdtQRImageUpload()
				}
				
				if (window.webkit != null) {
					window.webkit.messageHandlers.jsToOcWithPrams.postMessage({"camera": "normal"})
				}
			},
			set_qrcode_pic(path) {
				this.usdt.usdt_image = path

				this.$axios({
					method: 'post',
					url: 'cash_withdraw/resize', 
					data: {
						token: this.formItem.token,
						image: path
					},
				}).then ((res) => {
					if (res.success) {
						this.image_preview = res.data.preview
					} else {
						this.$dialog.alert({
							message: res.error_text
						})
					}
				})
			},
			init() {

				this.$axios({
					method: 'post',
					url: 'cash_withdraw/index', 
					data: {
						token: this.formItem.token
					}   
				}).then ((res) => {
					if (res.success) {
						this.hk.name = res.data.name

						switch (res.data.bank_type) {
							case 2:
								this.hk.country = res.data.bank_info_origin.country
								this.hk.bank = res.data.bank_info_origin.bank
								this.hk.bank_code = res.data.bank_info_origin.bank_code
								this.hk.bank_address = res.data.bank_info_origin.bank_address
								this.hk.account_name = res.data.bank_info_origin.account_name
								this.hk.account_no = res.data.bank_info_origin.account_no
								this.hk.account_address = res.data.bank_info_origin.account_address
								this.hk.swift_code = res.data.bank_info_origin.swift_code

								break

							case 3:

								this.usdt.type_text = res.data.bank_info_origin.type_text
								this.usdt.address = res.data.bank_info_origin.address

								this.indexType = this.usdt.type

								break
						}

					} else {
						this.$dialog.alert({
							message: res.error_text
						})
					}
				})
			
			},
			next() {
				this.$dialog.confirm({
					message: this.translate('please_confirm_receiving_address')
				}).then(() => {
	
					switch (this.type) {

						case '2':

							this.formItem.bank_info = JSON.stringify(this.hk)

							// this.formItem.bank_info = JSON.stringify({
							// 	'姓名'			: this.hk.name,
							// 	'国家'			: this.hk.country,
							// 	'银行'			: this.hk.bank,
							// 	'银行地址'		: this.hk.bank_address,
							// 	'账户名'		: this.hk.account_name,
							// 	'银行卡号'  	: this.hk.account_no,
							// 	'账户地址'  	: this.hk.account_address,
							// 	'SWIFT CODE'	: this.hk.swift_code
							// })

							break

						case '3':

							this.formItem.bank_info = JSON.stringify(this.usdt)

							// this.formItem.bank_info = JSON.stringify({
							// 	'主网类型'		: this.usdt.type_text,
							// 	'地址'			: this.usdt.address,
							// 	'图片'			: this.usdt.usdt_image
							// })

							break
					}

					this.$axios({
						method: 'post',
						url: 'cash_withdraw/insert', 
						data: this.formItem
					}).then ((res) => {
						
						this.$toast.clear()
						
						if (res.success) {
							this.$toast.success({
								message:this.translate('withdrawal_success_submitted')
							})

							window.sessionStorage.removeItem('bank_type')
							window.sessionStorage.removeItem('c')
							window.sessionStorage.removeItem('symbol')
							window.sessionStorage.removeItem('total')

							this.$router.replace('/chujin/history?token=' + this.formItem.token)
						} else {
							this.$dialog.alert({
								message: res.error_text
							})
						}
					})
				}).catch(() => {
					return 0
				})

				
			},
			onConfirmType(value, index) {
				this.indexType = index

				this.usdt.type = value.type
				this.usdt.type_text = value.text

				this.showType = false
			},
		}
	}
</script>
